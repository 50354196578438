import path from 'node:path';

export const sources = () => {
	'use server';
	return Object.entries(
		import.meta.glob<Record<keyof typeof data, Record<string, Document>>>('../../../../documents/**/*.md', {
			query: '?raw',
			import: 'default',
		}),
	).reduce(
		(memo, [key, val]) => {
			const docType = path.basename(path.dirname(key));
			const slug = path.basename(key, '.md');
			if (!(docType in memo)) {
				memo[docType] = {};
			}
			// @ts-expect-error
			memo[docType][slug] = val;
			return memo;
		},
		{} as Record<keyof typeof data, Record<string, Document>>,
	);
};

export const data = Object.entries(
	import.meta.glob<Record<string, Document>>('../../../../data/*.json', { import: 'default' }),
).reduce(
	(memo, [key, val]) => {
		const newKey = key.replace(/(?:\.\.\/)*data\/([\w-]+)\.json$/, '$1');
		memo[newKey] = val;
		return memo;
	},
	{} as Record<string, () => Promise<Record<string, Document>>>,
);

export type Document = { title: string; slug: string; date?: string; image?: string } & Record<string, string>;
